<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="active = false">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>

                        <h1 class="headline" style="white-space: nowrap">Agregar feriado</h1>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <validation-observer ref="form">
                        <v-form>
                            <validation-provider name="date" v-slot="{ errors }" rules="required">
                                <v-menu
                                    v-model="dateMenuOpen"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            label="Día"
                                            color="primary"
                                            v-model="data.date"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            v-on="on"
                                            :error-messages="errors"
                                        ></v-text-field>
                                    </template>

                                    <v-date-picker
                                        v-model="data.date"
                                        @input="dateMenuOpen = false"
                                        no-title
                                        :first-day-of-week="1"
                                        locale="es-AR"
                                        event-color="green lighten-1"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </validation-provider>

                            <validation-provider name="description" v-slot="{ errors }" rules="required">
                                <v-text-field
                                    v-model="data.name"
                                    label="Descripción"
                                    :error-messages="errors"
                                />
                            </validation-provider>
                        </v-form>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

               <v-btn color="primary"
                    @click="onSaveSelected"
                    :disabled="saving"
                    :loading="saving"
                    dark block
                >
                    Agregar
                </v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },

        props: {
            deliveryZone: Object,
            value: Boolean
        },

        data() {
            return {
                active: false,
                saving: false,

                data: {
                    date: null,
                    name: null,
                },

                dateMenuOpen: false,
            };
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            }
        },

        methods: {
            async onSaveSelected() {
                let result = await this.$refs.form.validate();
                if(!result) {
                    return;
                }

                this.saving = true;

                try {
                    await this.$store.dispatch('holidays/create', { 
                        date: this.data.date,
                        name: this.data.name,
                    });

                    this.data = {
                        date: null,
                        name: null,
                    };
                    this.$refs.form.reset();

                    this.active = false;
                }
                catch (err) {
                    console.error(err);
                }
                finally {
                    this.saving = false;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
