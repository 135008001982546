<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10" v-if="item">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="active = false">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>

                        <h1 class="headline" style="white-space: nowrap">Eliminar feriado</h1>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    Esta seguro que desea eliminar el feriado del dia {{ item.date | moment('dddd D MMMM YYYY') }}
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

               <v-btn color="red"
                    @click="onSaveSelected"
                    :disabled="saving"
                    :loading="saving"
                    dark block
                >
                    Eliminar
                </v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import WeekDaySelect from '@/ui/WeekDaySelect';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            WeekDaySelect
        },

        props: {
            item: Object,
            value: Boolean
        },

        data() {
            return {
                active: false,
                saving: false,
            };
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            }
        },

        methods: {
            async onSaveSelected() {
                this.saving = true;

                try {
                    await this.$store.dispatch('holidays/delete', { holiday: this.item });
                    this.active = false;
                }
                catch (err) {
                    console.error(err);
                }
                finally {
                    this.saving = false;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
